.tld-search {
  @extend .bg-light;
  @extend .container-fluid;

  .btn-lg {
    border-radius: $btn-border-radius;
  }

  &__filters {
    &-show {
      @include media-breakpoint-up(md) {
        display: none;
      }
      background-color: $gray-200;
      border-bottom: $border-search;
      border-top: $border-search;

      display: block;
      font-family: $font-family-sans-serif;
      font-size: $font-size-sm;
      font-weight: 400;
      letter-spacing: .25rem;
      padding: 0;
      text-align: center;
      text-transform: uppercase;

      button {
        color: darken($primary, 40%);
        font-weight: $font-weight-bold;
        height: 4rem;
        width: 100%;
      }
    }
  }

  &__lead {
    font-size: $font-size-sm;
    text-align: center;
  }

  &__results {
    @extend .col-12;
    @extend .col-md-8;
    @extend .col-lg-9;
    @extend .px-0;
    @extend .px-sm-3;
    @extend .py-3;

    background-color: theme-color('light');

    .loader {
      .fas {
        color: $gray-500;
      }
    }
  }

  &__row {
    @extend .row;
  }

  .searchandfilter {
    @include media-breakpoint-up(md) {
      display: block;
    }

    display: none;
  }
}
