:root {
  --blue: #0255a3;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #8a252a;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #5a8a41;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #687783;
  --gray-dark: #3d464d;
  --primary: #0255a3;
  --secondary: #344786;
  --success: #5a8a41;
  --info: #001325;
  --warning: #ffc107;
  --danger: #8a252a;
  --light: #f9fafb;
  --dark: #3d464d;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2b3136;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.75rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0255a3;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0f89fc;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #687783;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .sidebar .widget .widget-title, .h4, .h5, .h6 {
  margin-bottom: 0.75rem;
  font-family: "Zilla Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(1.375rem + 1.5vw) ; } }

h2, .h2 {
  font-size: 2rem; }
  @media (max-width: 1200px) {
    h2, .h2 {
      font-size: calc(1.325rem + 0.9vw) ; } }

h3, .h3, .sidebar .widget .widget-title {
  font-size: 1.75rem; }
  @media (max-width: 1200px) {
    h3, .h3, .sidebar .widget .widget-title {
      font-size: calc(1.3rem + 0.6vw) ; } }

h4, .h4 {
  font-size: 1.5rem; }
  @media (max-width: 1200px) {
    h4, .h4 {
      font-size: calc(1.275rem + 0.3vw) ; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.525rem + 3.3vw) ; } }

.display-2 {
  font-size: 3.25rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.45rem + 2.4vw) ; } }

.display-3 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.375rem + 1.5vw) ; } }

.display-4 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.325rem + 0.9vw) ; } }

hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #687783; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #bbc2c9;
  border-radius: 0.4rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.75rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #687783; }

.container, .entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6,
.entry-content p, .entry-content ul, .wp-block-image,
.wp-block-quote,
.wp-block-button.aligncenter,
.wp-block-media-text {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container, .entry-content h1,
    .entry-content h2,
    .entry-content h3,
    .entry-content h4,
    .entry-content h5,
    .entry-content h6,
    .entry-content p, .entry-content ul, .wp-block-image,
    .wp-block-quote,
    .wp-block-button.aligncenter,
    .wp-block-media-text {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container, .entry-content h1,
    .entry-content h2,
    .entry-content h3,
    .entry-content h4,
    .entry-content h5,
    .entry-content h6,
    .entry-content p, .entry-content ul, .wp-block-image,
    .wp-block-quote,
    .wp-block-button.aligncenter,
    .wp-block-media-text {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container, .entry-content h1,
    .entry-content h2,
    .entry-content h3,
    .entry-content h4,
    .entry-content h5,
    .entry-content h6,
    .entry-content p, .entry-content ul, .wp-block-image,
    .wp-block-quote,
    .wp-block-button.aligncenter,
    .wp-block-media-text {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container, .entry-content h1,
    .entry-content h2,
    .entry-content h3,
    .entry-content h4,
    .entry-content h5,
    .entry-content h6,
    .entry-content p, .entry-content ul, .wp-block-image,
    .wp-block-quote,
    .wp-block-button.aligncenter,
    .wp-block-media-text {
      max-width: 1140px; } }

.container-fluid, .post-navigation, .entry-footer, .tld-search {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row, .search-form__search, .tld-search__search-form, .tld-search__row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters, .search-form__search, .tld-search__search-form {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col, .search-form__search > .col, .tld-search__search-form > .col,
  .no-gutters > [class*="col-"],
  .search-form__search > [class*="col-"],
  .tld-search__search-form > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .tld-search__filters, .tld-search__results, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12, .tld-search__filters, .tld-search__results {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4, .tld-search__filters {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8, .tld-search__results {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3, .tld-search__filters {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9, .tld-search__results {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.form-control, .search-form__search-input, .tld-search__search-form .sf-input-text {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #515d66;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #9fa9b2;
  border-radius: 0.4rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .search-form__search-input, .tld-search__search-form .sf-input-text {
      transition: none; } }
  .form-control::-ms-expand, .search-form__search-input::-ms-expand, .tld-search__search-form .sf-input-text::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus, .search-form__search-input:focus, .tld-search__search-form .sf-input-text:focus {
    color: #515d66;
    background-color: #fff;
    border-color: #2896fc;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(2, 85, 163, 0.25); }
  .form-control::placeholder, .search-form__search-input::placeholder, .tld-search__search-form .sf-input-text::placeholder {
    color: #687783;
    opacity: 1; }
  .form-control:disabled, .search-form__search-input:disabled, .tld-search__search-form .sf-input-text:disabled, .form-control[readonly], .search-form__search-input[readonly], .tld-search__search-form .sf-input-text[readonly] {
    background-color: #d7dcdf;
    opacity: 1; }

select.form-control:focus::-ms-value, select.search-form__search-input:focus::-ms-value, .tld-search__search-form select.sf-input-text:focus::-ms-value {
  color: #515d66;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #2b3136;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.125rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.75rem; }

select.form-control[size], select.search-form__search-input[size], .tld-search__search-form select.sf-input-text[size], select.form-control[multiple], select.search-form__search-input[multiple], .tld-search__search-form select.sf-input-text[multiple] {
  height: auto; }

textarea.form-control, textarea.search-form__search-input, .tld-search__search-form textarea.sf-input-text {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #687783; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #5a8a41; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(90, 138, 65, 0.9);
  border-radius: 0.4rem; }

.was-validated .form-control:valid, .was-validated .search-form__search-input:valid, .was-validated .tld-search__search-form .sf-input-text:valid, .tld-search__search-form .was-validated .sf-input-text:valid, .form-control.is-valid, .is-valid.search-form__search-input, .tld-search__search-form .is-valid.sf-input-text {
  border-color: #5a8a41;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%235a8a41' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .was-validated .search-form__search-input:valid:focus, .was-validated .tld-search__search-form .sf-input-text:valid:focus, .tld-search__search-form .was-validated .sf-input-text:valid:focus, .form-control.is-valid:focus, .is-valid.search-form__search-input:focus, .tld-search__search-form .is-valid.sf-input-text:focus {
    border-color: #5a8a41;
    box-shadow: 0 0 0 0.2rem rgba(90, 138, 65, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback, .was-validated .search-form__search-input:valid ~ .valid-feedback, .was-validated .tld-search__search-form .sf-input-text:valid ~ .valid-feedback, .tld-search__search-form .was-validated .sf-input-text:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip,
  .was-validated .search-form__search-input:valid ~ .valid-tooltip,
  .was-validated .tld-search__search-form .sf-input-text:valid ~ .valid-tooltip,
  .tld-search__search-form .was-validated .sf-input-text:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .is-valid.search-form__search-input ~ .valid-feedback, .tld-search__search-form .is-valid.sf-input-text ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip,
  .is-valid.search-form__search-input ~ .valid-tooltip,
  .tld-search__search-form .is-valid.sf-input-text ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, .was-validated textarea.search-form__search-input:valid, .was-validated .tld-search__search-form textarea.sf-input-text:valid, .tld-search__search-form .was-validated textarea.sf-input-text:valid, textarea.form-control.is-valid, textarea.is-valid.search-form__search-input, .tld-search__search-form textarea.is-valid.sf-input-text {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #5a8a41;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233d464d' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%235a8a41' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #5a8a41;
    box-shadow: 0 0 0 0.2rem rgba(90, 138, 65, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #5a8a41; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #5a8a41; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #5a8a41; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #71ad51;
  background-color: #71ad51; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(90, 138, 65, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #5a8a41; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #5a8a41; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #5a8a41;
  box-shadow: 0 0 0 0.2rem rgba(90, 138, 65, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #8a252a; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(138, 37, 42, 0.9);
  border-radius: 0.4rem; }

.was-validated .form-control:invalid, .was-validated .search-form__search-input:invalid, .was-validated .tld-search__search-form .sf-input-text:invalid, .tld-search__search-form .was-validated .sf-input-text:invalid, .form-control.is-invalid, .is-invalid.search-form__search-input, .tld-search__search-form .is-invalid.sf-input-text {
  border-color: #8a252a;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%238a252a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%238a252a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .was-validated .search-form__search-input:invalid:focus, .was-validated .tld-search__search-form .sf-input-text:invalid:focus, .tld-search__search-form .was-validated .sf-input-text:invalid:focus, .form-control.is-invalid:focus, .is-invalid.search-form__search-input:focus, .tld-search__search-form .is-invalid.sf-input-text:focus {
    border-color: #8a252a;
    box-shadow: 0 0 0 0.2rem rgba(138, 37, 42, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .search-form__search-input:invalid ~ .invalid-feedback, .was-validated .tld-search__search-form .sf-input-text:invalid ~ .invalid-feedback, .tld-search__search-form .was-validated .sf-input-text:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip,
  .was-validated .search-form__search-input:invalid ~ .invalid-tooltip,
  .was-validated .tld-search__search-form .sf-input-text:invalid ~ .invalid-tooltip,
  .tld-search__search-form .was-validated .sf-input-text:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .is-invalid.search-form__search-input ~ .invalid-feedback, .tld-search__search-form .is-invalid.sf-input-text ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip,
  .is-invalid.search-form__search-input ~ .invalid-tooltip,
  .tld-search__search-form .is-invalid.sf-input-text ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, .was-validated textarea.search-form__search-input:invalid, .was-validated .tld-search__search-form textarea.sf-input-text:invalid, .tld-search__search-form .was-validated textarea.sf-input-text:invalid, textarea.form-control.is-invalid, textarea.is-invalid.search-form__search-input, .tld-search__search-form textarea.is-invalid.sf-input-text {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #8a252a;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233d464d' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%238a252a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%238a252a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #8a252a;
    box-shadow: 0 0 0 0.2rem rgba(138, 37, 42, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #8a252a; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #8a252a; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #8a252a; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #b23036;
  background-color: #b23036; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(138, 37, 42, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #8a252a; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #8a252a; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #8a252a;
  box-shadow: 0 0 0 0.2rem rgba(138, 37, 42, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline .search-form__search-input, .form-inline .tld-search__search-form .sf-input-text, .tld-search__search-form .form-inline .sf-input-text {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn, .gform_button, .search-form__submit_desktop, .search-form__submit_mobile, .tld-search .searchandfilter .sf-field-reset input, .post-edit-link, .cat-links a, .tags-links a {
  display: inline-block;
  font-weight: 400;
  color: #2b3136;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, .gform_button, .search-form__submit_desktop, .search-form__submit_mobile, .tld-search .searchandfilter .sf-field-reset input, .post-edit-link, .cat-links a, .tags-links a {
      transition: none; } }
  .btn:hover, .gform_button:hover, .search-form__submit_desktop:hover, .search-form__submit_mobile:hover, .tld-search .searchandfilter .sf-field-reset input:hover, .post-edit-link:hover, .cat-links a:hover, .tags-links a:hover {
    color: #2b3136;
    text-decoration: none; }
  .btn:focus, .gform_button:focus, .search-form__submit_desktop:focus, .search-form__submit_mobile:focus, .tld-search .searchandfilter .sf-field-reset input:focus, .post-edit-link:focus, .cat-links a:focus, .tags-links a:focus, .btn.focus, .focus.gform_button, .focus.search-form__submit_desktop, .focus.search-form__submit_mobile, .tld-search .searchandfilter .sf-field-reset input.focus, .focus.post-edit-link, .cat-links a.focus, .tags-links a.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(2, 85, 163, 0.25); }
  .btn.disabled, .disabled.gform_button, .disabled.search-form__submit_desktop, .disabled.search-form__submit_mobile, .tld-search .searchandfilter .sf-field-reset input.disabled, .disabled.post-edit-link, .cat-links a.disabled, .tags-links a.disabled, .btn:disabled, .gform_button:disabled, .search-form__submit_desktop:disabled, .search-form__submit_mobile:disabled, .tld-search .searchandfilter .sf-field-reset input:disabled, .post-edit-link:disabled, .cat-links a:disabled, .tags-links a:disabled {
    opacity: 0.65; }

a.btn.disabled, a.disabled.gform_button, a.disabled.search-form__submit_desktop, a.disabled.search-form__submit_mobile, a.disabled.post-edit-link, .cat-links a.disabled, .tags-links a.disabled,
fieldset:disabled a.btn,
fieldset:disabled a.gform_button,
fieldset:disabled a.search-form__submit_desktop,
fieldset:disabled a.search-form__submit_mobile,
fieldset:disabled a.post-edit-link,
fieldset:disabled .cat-links a,
.cat-links fieldset:disabled a,
fieldset:disabled .tags-links a,
.tags-links fieldset:disabled a {
  pointer-events: none; }

.btn-primary, .gform_button, .search-form__submit_desktop, .search-form__submit_mobile {
  color: #fff;
  background-color: #0255a3;
  border-color: #0255a3; }
  .btn-primary:hover, .gform_button:hover, .search-form__submit_desktop:hover, .search-form__submit_mobile:hover {
    color: #fff;
    background-color: #02417d;
    border-color: #013b71; }
  .btn-primary:focus, .gform_button:focus, .search-form__submit_desktop:focus, .search-form__submit_mobile:focus, .btn-primary.focus, .focus.gform_button, .focus.search-form__submit_desktop, .focus.search-form__submit_mobile {
    box-shadow: 0 0 0 0.2rem rgba(40, 111, 177, 0.5); }
  .btn-primary.disabled, .disabled.gform_button, .disabled.search-form__submit_desktop, .disabled.search-form__submit_mobile, .btn-primary:disabled, .gform_button:disabled, .search-form__submit_desktop:disabled, .search-form__submit_mobile:disabled {
    color: #fff;
    background-color: #0255a3;
    border-color: #0255a3; }
  .btn-primary:not(:disabled):not(.disabled):active, .gform_button:not(:disabled):not(.disabled):active, .search-form__submit_desktop:not(:disabled):not(.disabled):active, .search-form__submit_mobile:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .gform_button:not(:disabled):not(.disabled).active, .search-form__submit_desktop:not(:disabled):not(.disabled).active, .search-form__submit_mobile:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle,
  .show > .dropdown-toggle.gform_button,
  .show > .dropdown-toggle.search-form__submit_desktop,
  .show > .dropdown-toggle.search-form__submit_mobile {
    color: #fff;
    background-color: #013b71;
    border-color: #013464; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .gform_button:not(:disabled):not(.disabled):active:focus, .search-form__submit_desktop:not(:disabled):not(.disabled):active:focus, .search-form__submit_mobile:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .gform_button:not(:disabled):not(.disabled).active:focus, .search-form__submit_desktop:not(:disabled):not(.disabled).active:focus, .search-form__submit_mobile:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus,
    .show > .dropdown-toggle.gform_button:focus,
    .show > .dropdown-toggle.search-form__submit_desktop:focus,
    .show > .dropdown-toggle.search-form__submit_mobile:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 111, 177, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #344786;
  border-color: #344786; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #29386a;
    border-color: #263461; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 99, 152, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #344786;
    border-color: #344786; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #263461;
    border-color: #222f58; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 99, 152, 0.5); }

.btn-success {
  color: #fff;
  background-color: #5a8a41;
  border-color: #5a8a41; }
  .btn-success:hover {
    color: #fff;
    background-color: #497035;
    border-color: #436731; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(115, 156, 94, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #5a8a41;
    border-color: #5a8a41; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #436731;
    border-color: #3e5f2d; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(115, 156, 94, 0.5); }

.btn-info {
  color: #fff;
  background-color: #001325;
  border-color: #001325; }
  .btn-info:hover {
    color: #fff;
    background-color: black;
    border-color: black; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(39, 55, 70, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #001325;
    border-color: #001325; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(39, 55, 70, 0.5); }

.btn-warning {
  color: #2b3136;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #2b3136;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(223, 171, 14, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #2b3136;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #2b3136;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(223, 171, 14, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #8a252a;
  border-color: #8a252a; }
  .btn-danger:hover {
    color: #fff;
    background-color: #6c1d21;
    border-color: #621a1e; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(156, 70, 74, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #8a252a;
    border-color: #8a252a; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #621a1e;
    border-color: #58181b; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(156, 70, 74, 0.5); }

.btn-light {
  color: #2b3136;
  background-color: #f9fafb;
  border-color: #f9fafb; }
  .btn-light:hover {
    color: #2b3136;
    background-color: #e4e7ea;
    border-color: #dde1e4; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(218, 220, 221, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #2b3136;
    background-color: #f9fafb;
    border-color: #f9fafb; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #2b3136;
    background-color: #dde1e4;
    border-color: #d6dade; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(218, 220, 221, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #3d464d;
  border-color: #3d464d; }
  .btn-dark:hover {
    color: #fff;
    background-color: #2c3237;
    border-color: #262c30; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(90, 97, 103, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #3d464d;
    border-color: #3d464d; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #262c30;
    border-color: #212529; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(90, 97, 103, 0.5); }

.btn-outline-primary, .post-edit-link, .tags-links a {
  color: #0255a3;
  border-color: #0255a3; }
  .btn-outline-primary:hover, .post-edit-link:hover, .tags-links a:hover {
    color: #fff;
    background-color: #0255a3;
    border-color: #0255a3; }
  .btn-outline-primary:focus, .post-edit-link:focus, .tags-links a:focus, .btn-outline-primary.focus, .focus.post-edit-link, .tags-links a.focus {
    box-shadow: 0 0 0 0.2rem rgba(2, 85, 163, 0.5); }
  .btn-outline-primary.disabled, .disabled.post-edit-link, .tags-links a.disabled, .btn-outline-primary:disabled, .post-edit-link:disabled, .tags-links a:disabled {
    color: #0255a3;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .post-edit-link:not(:disabled):not(.disabled):active, .tags-links a:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .post-edit-link:not(:disabled):not(.disabled).active, .tags-links a:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle,
  .show > .dropdown-toggle.post-edit-link,
  .tags-links .show > a.dropdown-toggle {
    color: #fff;
    background-color: #0255a3;
    border-color: #0255a3; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .post-edit-link:not(:disabled):not(.disabled):active:focus, .tags-links a:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .post-edit-link:not(:disabled):not(.disabled).active:focus, .tags-links a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus,
    .show > .dropdown-toggle.post-edit-link:focus,
    .tags-links .show > a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(2, 85, 163, 0.5); }

.btn-outline-secondary {
  color: #344786;
  border-color: #344786; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #344786;
    border-color: #344786; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 71, 134, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #344786;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #344786;
    border-color: #344786; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 71, 134, 0.5); }

.btn-outline-success, .cat-links a {
  color: #5a8a41;
  border-color: #5a8a41; }
  .btn-outline-success:hover, .cat-links a:hover {
    color: #fff;
    background-color: #5a8a41;
    border-color: #5a8a41; }
  .btn-outline-success:focus, .cat-links a:focus, .btn-outline-success.focus, .cat-links a.focus {
    box-shadow: 0 0 0 0.2rem rgba(90, 138, 65, 0.5); }
  .btn-outline-success.disabled, .cat-links a.disabled, .btn-outline-success:disabled, .cat-links a:disabled {
    color: #5a8a41;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .cat-links a:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .cat-links a:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle,
  .cat-links .show > a.dropdown-toggle {
    color: #fff;
    background-color: #5a8a41;
    border-color: #5a8a41; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .cat-links a:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .cat-links a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus,
    .cat-links .show > a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(90, 138, 65, 0.5); }

.btn-outline-info {
  color: #001325;
  border-color: #001325; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #001325;
    border-color: #001325; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 19, 37, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #001325;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #001325;
    border-color: #001325; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 19, 37, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #2b3136;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #2b3136;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger, .tld-search .searchandfilter .sf-field-reset input {
  color: #8a252a;
  border-color: #8a252a; }
  .btn-outline-danger:hover, .tld-search .searchandfilter .sf-field-reset input:hover {
    color: #fff;
    background-color: #8a252a;
    border-color: #8a252a; }
  .btn-outline-danger:focus, .tld-search .searchandfilter .sf-field-reset input:focus, .btn-outline-danger.focus, .tld-search .searchandfilter .sf-field-reset input.focus {
    box-shadow: 0 0 0 0.2rem rgba(138, 37, 42, 0.5); }
  .btn-outline-danger.disabled, .tld-search .searchandfilter .sf-field-reset input.disabled, .btn-outline-danger:disabled, .tld-search .searchandfilter .sf-field-reset input:disabled {
    color: #8a252a;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .tld-search .searchandfilter .sf-field-reset input:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .tld-search .searchandfilter .sf-field-reset input:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle,
  .tld-search .searchandfilter .sf-field-reset .show > input.dropdown-toggle {
    color: #fff;
    background-color: #8a252a;
    border-color: #8a252a; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .tld-search .searchandfilter .sf-field-reset input:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .tld-search .searchandfilter .sf-field-reset input:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus,
    .tld-search .searchandfilter .sf-field-reset .show > input.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(138, 37, 42, 0.5); }

.btn-outline-light {
  color: #f9fafb;
  border-color: #f9fafb; }
  .btn-outline-light:hover {
    color: #2b3136;
    background-color: #f9fafb;
    border-color: #f9fafb; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(249, 250, 251, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f9fafb;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #2b3136;
    background-color: #f9fafb;
    border-color: #f9fafb; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(249, 250, 251, 0.5); }

.btn-outline-dark {
  color: #3d464d;
  border-color: #3d464d; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #3d464d;
    border-color: #3d464d; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(61, 70, 77, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #3d464d;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #3d464d;
    border-color: #3d464d; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(61, 70, 77, 0.5); }

.btn-link {
  font-weight: 400;
  color: #0255a3;
  text-decoration: none; }
  .btn-link:hover {
    color: #0f89fc;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #687783;
    pointer-events: none; }

.btn-lg, .search-form__submit_desktop {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.4rem; }

.btn-sm, .tags-links a {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #2b3136;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.4rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.75rem 0;
  overflow: hidden;
  border-top: 1px solid #d7dcdf; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #2b3136;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #202428;
    text-decoration: none;
    background-color: #f4f5f6; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0255a3; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #687783;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #687783;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #2b3136; }

.nav, .search-form__nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #687783;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #bbc2c9; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #d7dcdf #d7dcdf #bbc2c9; }
    .nav-tabs .nav-link.disabled {
      color: #687783;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #515d66;
    background-color: #fff;
    border-color: #bbc2c9 #bbc2c9 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.4rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0255a3; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.5rem; }
  .navbar > .container, .entry-content .navbar > h1,
  .entry-content .navbar > h2,
  .entry-content .navbar > h3,
  .entry-content .navbar > h4,
  .entry-content .navbar > h5,
  .entry-content .navbar > h6,
  .entry-content .navbar > p, .entry-content .navbar > ul, .navbar > .wp-block-image,
  .navbar > .wp-block-quote,
  .navbar > .wp-block-button.aligncenter,
  .navbar > .wp-block-media-text,
  .navbar > .container-fluid,
  .navbar > .post-navigation,
  .navbar > .entry-footer,
  .navbar > .tld-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1.5rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.125rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .entry-content .navbar-expand-sm > h1,
  .entry-content .navbar-expand-sm > h2,
  .entry-content .navbar-expand-sm > h3,
  .entry-content .navbar-expand-sm > h4,
  .entry-content .navbar-expand-sm > h5,
  .entry-content .navbar-expand-sm > h6,
  .entry-content .navbar-expand-sm > p, .entry-content .navbar-expand-sm > ul, .navbar-expand-sm > .wp-block-image,
  .navbar-expand-sm > .wp-block-quote,
  .navbar-expand-sm > .wp-block-button.aligncenter,
  .navbar-expand-sm > .wp-block-media-text,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .post-navigation,
  .navbar-expand-sm > .entry-footer,
  .navbar-expand-sm > .tld-search {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container, .entry-content .navbar-expand-sm > h1,
    .entry-content .navbar-expand-sm > h2,
    .entry-content .navbar-expand-sm > h3,
    .entry-content .navbar-expand-sm > h4,
    .entry-content .navbar-expand-sm > h5,
    .entry-content .navbar-expand-sm > h6,
    .entry-content .navbar-expand-sm > p, .entry-content .navbar-expand-sm > ul, .navbar-expand-sm > .wp-block-image,
    .navbar-expand-sm > .wp-block-quote,
    .navbar-expand-sm > .wp-block-button.aligncenter,
    .navbar-expand-sm > .wp-block-media-text,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .post-navigation,
    .navbar-expand-sm > .entry-footer,
    .navbar-expand-sm > .tld-search {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .entry-content .navbar-expand-md > h1,
  .entry-content .navbar-expand-md > h2,
  .entry-content .navbar-expand-md > h3,
  .entry-content .navbar-expand-md > h4,
  .entry-content .navbar-expand-md > h5,
  .entry-content .navbar-expand-md > h6,
  .entry-content .navbar-expand-md > p, .entry-content .navbar-expand-md > ul, .navbar-expand-md > .wp-block-image,
  .navbar-expand-md > .wp-block-quote,
  .navbar-expand-md > .wp-block-button.aligncenter,
  .navbar-expand-md > .wp-block-media-text,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .post-navigation,
  .navbar-expand-md > .entry-footer,
  .navbar-expand-md > .tld-search {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container, .entry-content .navbar-expand-md > h1,
    .entry-content .navbar-expand-md > h2,
    .entry-content .navbar-expand-md > h3,
    .entry-content .navbar-expand-md > h4,
    .entry-content .navbar-expand-md > h5,
    .entry-content .navbar-expand-md > h6,
    .entry-content .navbar-expand-md > p, .entry-content .navbar-expand-md > ul, .navbar-expand-md > .wp-block-image,
    .navbar-expand-md > .wp-block-quote,
    .navbar-expand-md > .wp-block-button.aligncenter,
    .navbar-expand-md > .wp-block-media-text,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .post-navigation,
    .navbar-expand-md > .entry-footer,
    .navbar-expand-md > .tld-search {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .entry-content .navbar-expand-lg > h1,
  .entry-content .navbar-expand-lg > h2,
  .entry-content .navbar-expand-lg > h3,
  .entry-content .navbar-expand-lg > h4,
  .entry-content .navbar-expand-lg > h5,
  .entry-content .navbar-expand-lg > h6,
  .entry-content .navbar-expand-lg > p, .entry-content .navbar-expand-lg > ul, .navbar-expand-lg > .wp-block-image,
  .navbar-expand-lg > .wp-block-quote,
  .navbar-expand-lg > .wp-block-button.aligncenter,
  .navbar-expand-lg > .wp-block-media-text,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .post-navigation,
  .navbar-expand-lg > .entry-footer,
  .navbar-expand-lg > .tld-search {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container, .entry-content .navbar-expand-lg > h1,
    .entry-content .navbar-expand-lg > h2,
    .entry-content .navbar-expand-lg > h3,
    .entry-content .navbar-expand-lg > h4,
    .entry-content .navbar-expand-lg > h5,
    .entry-content .navbar-expand-lg > h6,
    .entry-content .navbar-expand-lg > p, .entry-content .navbar-expand-lg > ul, .navbar-expand-lg > .wp-block-image,
    .navbar-expand-lg > .wp-block-quote,
    .navbar-expand-lg > .wp-block-button.aligncenter,
    .navbar-expand-lg > .wp-block-media-text,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .post-navigation,
    .navbar-expand-lg > .entry-footer,
    .navbar-expand-lg > .tld-search {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .entry-content .navbar-expand-xl > h1,
  .entry-content .navbar-expand-xl > h2,
  .entry-content .navbar-expand-xl > h3,
  .entry-content .navbar-expand-xl > h4,
  .entry-content .navbar-expand-xl > h5,
  .entry-content .navbar-expand-xl > h6,
  .entry-content .navbar-expand-xl > p, .entry-content .navbar-expand-xl > ul, .navbar-expand-xl > .wp-block-image,
  .navbar-expand-xl > .wp-block-quote,
  .navbar-expand-xl > .wp-block-button.aligncenter,
  .navbar-expand-xl > .wp-block-media-text,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .post-navigation,
  .navbar-expand-xl > .entry-footer,
  .navbar-expand-xl > .tld-search {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container, .entry-content .navbar-expand-xl > h1,
    .entry-content .navbar-expand-xl > h2,
    .entry-content .navbar-expand-xl > h3,
    .entry-content .navbar-expand-xl > h4,
    .entry-content .navbar-expand-xl > h5,
    .entry-content .navbar-expand-xl > h6,
    .entry-content .navbar-expand-xl > p, .entry-content .navbar-expand-xl > ul, .navbar-expand-xl > .wp-block-image,
    .navbar-expand-xl > .wp-block-quote,
    .navbar-expand-xl > .wp-block-button.aligncenter,
    .navbar-expand-xl > .wp-block-media-text,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .post-navigation,
    .navbar-expand-xl > .entry-footer,
    .navbar-expand-xl > .tld-search {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container, .entry-content .navbar-expand > h1,
  .entry-content .navbar-expand > h2,
  .entry-content .navbar-expand > h3,
  .entry-content .navbar-expand > h4,
  .entry-content .navbar-expand > h5,
  .entry-content .navbar-expand > h6,
  .entry-content .navbar-expand > p, .entry-content .navbar-expand > ul, .navbar-expand > .wp-block-image,
  .navbar-expand > .wp-block-quote,
  .navbar-expand > .wp-block-button.aligncenter,
  .navbar-expand > .wp-block-media-text,
  .navbar-expand > .container-fluid,
  .navbar-expand > .post-navigation,
  .navbar-expand > .entry-footer,
  .navbar-expand > .tld-search {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container, .entry-content .navbar-expand > h1,
  .entry-content .navbar-expand > h2,
  .entry-content .navbar-expand > h3,
  .entry-content .navbar-expand > h4,
  .entry-content .navbar-expand > h5,
  .entry-content .navbar-expand > h6,
  .entry-content .navbar-expand > p, .entry-content .navbar-expand > ul, .navbar-expand > .wp-block-image,
  .navbar-expand > .wp-block-quote,
  .navbar-expand > .wp-block-button.aligncenter,
  .navbar-expand > .wp-block-media-text,
  .navbar-expand > .container-fluid,
  .navbar-expand > .post-navigation,
  .navbar-expand > .entry-footer,
  .navbar-expand > .tld-search {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card, .search-form__container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.4rem; }
  .card > hr, .search-form__container > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child, .search-form__container > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem; }
  .card > .list-group:last-child .list-group-item:last-child, .search-form__container > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.4rem - 1px) calc(0.4rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.4rem - 1px) calc(0.4rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.4rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.4rem - 1px);
  border-top-right-radius: calc(0.4rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.4rem - 1px);
  border-bottom-left-radius: calc(0.4rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card, .card-deck .search-form__container {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card, .card-deck .search-form__container {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card, .card-group > .search-form__container {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card, .card-group > .search-form__container {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card, .card-group > .search-form__container + .card, .card-group > .card + .search-form__container, .card-group > .search-form__container + .search-form__container {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child), .card-group > .search-form__container:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top, .card-group > .search-form__container:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header,
          .card-group > .search-form__container:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .search-form__container:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer,
          .card-group > .search-form__container:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child), .card-group > .search-form__container:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top, .card-group > .search-form__container:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header,
          .card-group > .search-form__container:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .search-form__container:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer,
          .card-group > .search-form__container:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card, .card-columns .search-form__container {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card, .card-columns .search-form__container {
      display: inline-block;
      width: 100%; } }

.accordion > .card, .accordion > .search-form__container {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child, .accordion > .search-form__container:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type), .accordion > .search-form__container:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type, .accordion > .search-form__container:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type, .accordion > .search-form__container:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header, .accordion > .search-form__container .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #d7dcdf;
  border-radius: 0.4rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #687783;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #687783; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.4rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0255a3;
  background-color: #fff;
  border: 1px solid #bbc2c9; }
  .page-link:hover {
    z-index: 2;
    color: #0f89fc;
    text-decoration: none;
    background-color: #d7dcdf;
    border-color: #bbc2c9; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(2, 85, 163, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #0255a3;
  border-color: #0255a3; }

.page-item.disabled .page-link {
  color: #687783;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #bbc2c9; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  @media (max-width: 1200px) {
    .close {
      font-size: calc(1.275rem + 0.3vw) ; } }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.75rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #bbc2c9;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #bbc2c9;
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #0255a3 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #013b71 !important; }

.bg-secondary, .footer {
  background-color: #344786 !important; }

a.bg-secondary:hover, a.footer:hover, a.bg-secondary:focus, a.footer:focus,
button.bg-secondary:hover,
button.footer:hover,
button.bg-secondary:focus,
button.footer:focus {
  background-color: #263461 !important; }

.bg-success {
  background-color: #5a8a41 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #436731 !important; }

.bg-info {
  background-color: #001325 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: black !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #8a252a !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #621a1e !important; }

.bg-light, .sidebar .widget-area, .entry-footer, .tld-search {
  background-color: #f9fafb !important; }

a.bg-light:hover, .sidebar a.widget-area:hover, a.entry-footer:hover, a.tld-search:hover, a.bg-light:focus, .sidebar a.widget-area:focus, a.entry-footer:focus, a.tld-search:focus,
button.bg-light:hover,
.sidebar button.widget-area:hover,
button.entry-footer:hover,
button.tld-search:hover,
button.bg-light:focus,
.sidebar button.widget-area:focus,
button.entry-footer:focus,
button.tld-search:focus {
  background-color: #dde1e4 !important; }

.bg-dark {
  background-color: #3d464d !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #262c30 !important; }

.bg-white, .tld-search__filters, .tease-post .entry-footer {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border, .sidebar .widget-area {
  border: 1px solid #bbc2c9 !important; }

.border-top, .entry-footer {
  border-top: 1px solid #bbc2c9 !important; }

.border-right {
  border-right: 1px solid #bbc2c9 !important; }

.border-bottom, .sidebar .widget .widget-title, .entry-footer {
  border-bottom: 1px solid #bbc2c9 !important; }

.border-left {
  border-left: 1px solid #bbc2c9 !important; }

.border-0, .tease-post .entry-footer {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary, .sidebar .widget .widget-title {
  border-color: #0255a3 !important; }

.border-secondary {
  border-color: #344786 !important; }

.border-success {
  border-color: #5a8a41 !important; }

.border-info {
  border-color: #001325 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #8a252a !important; }

.border-light {
  border-color: #f9fafb !important; }

.border-dark {
  border-color: #3d464d !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.125rem !important; }

.rounded, .sidebar .widget-area {
  border-radius: 0.4rem !important; }

.rounded-top {
  border-top-left-radius: 0.4rem !important;
  border-top-right-radius: 0.4rem !important; }

.rounded-right {
  border-top-right-radius: 0.4rem !important;
  border-bottom-right-radius: 0.4rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.4rem !important;
  border-bottom-left-radius: 0.4rem !important; }

.rounded-left {
  border-top-left-radius: 0.4rem !important;
  border-bottom-left-radius: 0.4rem !important; }

.rounded-lg {
  border-radius: 0.75rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block, .sidebar .widget ul li a {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex, .post-navigation .nav-links, .entry-footer {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between, .post-navigation .nav-links, .entry-footer {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center, .search-form__search, .tld-search__search-form, .entry-footer {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0, .site-content, .sidebar .widget .widget-title,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0,
.tld-search .searchandfilter .sf-field-category,
.tld-search .searchandfilter .sf-field-post_type,
.tld-search .searchandfilter .sf-field-tag {
  margin-right: 0 !important; }

.mb-0, .header__desktop--titles .lead, .form-label-heading, .search-form__topics h4, .search-form__types h4, .tld-search .searchandfilter .sf-field-category h4,
.tld-search .searchandfilter .sf-field-post_type h4,
.tld-search .searchandfilter .sf-field-tag h4, .search-form__form label,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0, .tld-search .searchandfilter ul.children,
.mx-0,
.tld-search .searchandfilter .sf-field-category,
.tld-search .searchandfilter .sf-field-post_type,
.tld-search .searchandfilter .sf-field-tag {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.375rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.375rem !important; }

.mr-1, .cat-links a, .tags-links a,
.mx-1 {
  margin-right: 0.375rem !important; }

.mb-1, .cat-links a, .tags-links a,
.my-1 {
  margin-bottom: 0.375rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.375rem !important; }

.m-2 {
  margin: 0.75rem !important; }

.mt-2,
.my-2,
.entry-content ul li ul {
  margin-top: 0.75rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.75rem !important; }

.mb-2,
.my-2,
.entry-content ul li ul {
  margin-bottom: 0.75rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.75rem !important; }

.m-3 {
  margin: 1.5rem !important; }

.mt-3,
.my-3 {
  margin-top: 1.5rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1.5rem !important; }

.mb-3, .sidebar .widget-area, .sidebar .widget .widget-title,
.my-3 {
  margin-bottom: 1.5rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1.5rem !important; }

.m-4 {
  margin: 3rem !important; }

.mt-4,
.my-4 {
  margin-top: 3rem !important; }

.mr-4,
.mx-4 {
  margin-right: 3rem !important; }

.mb-4, .sidebar .widget,
.my-4 {
  margin-bottom: 3rem !important; }

.ml-4,
.mx-4 {
  margin-left: 3rem !important; }

.m-5 {
  margin: 4.5rem !important; }

.mt-5,
.my-5,
.entry-content ul {
  margin-top: 4.5rem !important; }

.mr-5,
.mx-5 {
  margin-right: 4.5rem !important; }

.mb-5, .jumbotron__cover,
.my-5,
.entry-content ul {
  margin-bottom: 4.5rem !important; }

.ml-5,
.mx-5 {
  margin-left: 4.5rem !important; }

.p-0, .tld-search .searchandfilter .sf-field-search, .tld-search .searchandfilter .sf-field-category,
.tld-search .searchandfilter .sf-field-post_type,
.tld-search .searchandfilter .sf-field-tag {
  padding: 0 !important; }

.pt-0, .home .site-content,
.py-0,
.tld-search .searchandfilter ul.children {
  padding-top: 0 !important; }

.pr-0, .header__desktop--top--nav .dropdown-menu .nav-item:last-child .nav-link,
.px-0,
.tld-search__filters,
.tld-search .searchandfilter ul,
.tease-post .entry-footer,
.tld-search__results {
  padding-right: 0 !important; }

.pb-0,
.py-0,
.tld-search .searchandfilter ul.children {
  padding-bottom: 0 !important; }

.pl-0, .search-form__nav .nav-item .accordion-dropdown-menu,
.px-0,
.tld-search__filters,
.tld-search .searchandfilter ul,
.tease-post .entry-footer,
.tld-search__results {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.375rem !important; }

.pt-1,
.py-1,
.tld-search .searchandfilter ul li {
  padding-top: 0.375rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.375rem !important; }

.pb-1,
.py-1,
.tld-search .searchandfilter ul li {
  padding-bottom: 0.375rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.375rem !important; }

.p-2, .header__mobile--actions-toggle, .header__mobile--actions-search {
  padding: 0.75rem !important; }

.pt-2,
.py-2,
.header__mobile--top--right,
.header__mobile .navbar .navbar-collapse,
.form-label-heading,
.search-form__topics h4,
.search-form__types h4,
.tld-search .searchandfilter .sf-field-category h4,
.tld-search .searchandfilter .sf-field-post_type h4,
.tld-search .searchandfilter .sf-field-tag h4,
.tld-search .searchandfilter ul {
  padding-top: 0.75rem !important; }

.pr-2,
.px-2,
.header__desktop--top--nav nav .nav-link {
  padding-right: 0.75rem !important; }

.pb-2,
.py-2,
.header__mobile--top--right,
.header__mobile .navbar .navbar-collapse,
.form-label-heading,
.search-form__topics h4,
.search-form__types h4,
.tld-search .searchandfilter .sf-field-category h4,
.tld-search .searchandfilter .sf-field-post_type h4,
.tld-search .searchandfilter .sf-field-tag h4,
.tld-search .searchandfilter ul {
  padding-bottom: 0.75rem !important; }

.pl-2,
.px-2,
.header__desktop--top--nav nav .nav-link {
  padding-left: 0.75rem !important; }

.p-3, .post-navigation, .entry-footer {
  padding: 1.5rem !important; }

.pt-3, .sidebar .widget-area,
.py-3,
.header__desktop--titles,
.tld-search__results {
  padding-top: 1.5rem !important; }

.pr-3, .header__desktop--top--nav nav .nav-item:last-child .nav-link,
.px-3,
.header__mobile .navbar .navbar-collapse,
.topics-nav .nav.navbar-nav .nav-link,
.topics-nav .navbar-nav.search-form__nav .nav-link {
  padding-right: 1.5rem !important; }

.pb-3, .sidebar .widget .widget-title,
.py-3,
.header__desktop--titles,
.tld-search__results {
  padding-bottom: 1.5rem !important; }

.pl-3,
.px-3,
.header__mobile .navbar .navbar-collapse,
.topics-nav .nav.navbar-nav .nav-link,
.topics-nav .navbar-nav.search-form__nav .nav-link {
  padding-left: 1.5rem !important; }

.p-4 {
  padding: 3rem !important; }

.pt-4, .site-content,
.py-4 {
  padding-top: 3rem !important; }

.pr-4,
.px-4 {
  padding-right: 3rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 3rem !important; }

.pl-4, .header__mobile--top--right,
.px-4 {
  padding-left: 3rem !important; }

.p-5 {
  padding: 4.5rem !important; }

.pt-5,
.py-5 {
  padding-top: 4.5rem !important; }

.pr-5,
.px-5 {
  padding-right: 4.5rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 4.5rem !important; }

.pl-5,
.px-5 {
  padding-left: 4.5rem !important; }

.m-n1 {
  margin: -0.375rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.375rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.375rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.375rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.375rem !important; }

.m-n2 {
  margin: -0.75rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.75rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.75rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.75rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.75rem !important; }

.m-n3 {
  margin: -1.5rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1.5rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1.5rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1.5rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1.5rem !important; }

.m-n4 {
  margin: -3rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -3rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -3rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -3rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -3rem !important; }

.m-n5 {
  margin: -4.5rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -4.5rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -4.5rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -4.5rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -4.5rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.375rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.375rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.375rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.375rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.375rem !important; }
  .m-sm-2 {
    margin: 0.75rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.75rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.75rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.75rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.75rem !important; }
  .m-sm-3 {
    margin: 1.5rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.5rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.5rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.5rem !important; }
  .m-sm-4 {
    margin: 3rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 3rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 3rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 3rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 3rem !important; }
  .m-sm-5 {
    margin: 4.5rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 4.5rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 4.5rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 4.5rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 4.5rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.375rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.375rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.375rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.375rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.375rem !important; }
  .p-sm-2 {
    padding: 0.75rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.75rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.75rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.75rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.75rem !important; }
  .p-sm-3 {
    padding: 1.5rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.5rem !important; }
  .pr-sm-3,
  .px-sm-3,
  .tld-search__results {
    padding-right: 1.5rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-3,
  .px-sm-3,
  .tld-search__results {
    padding-left: 1.5rem !important; }
  .p-sm-4 {
    padding: 3rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 3rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 3rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 3rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 3rem !important; }
  .p-sm-5 {
    padding: 4.5rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 4.5rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 4.5rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 4.5rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 4.5rem !important; }
  .m-sm-n1 {
    margin: -0.375rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.375rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.375rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.375rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.375rem !important; }
  .m-sm-n2 {
    margin: -0.75rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.75rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.75rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.75rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.75rem !important; }
  .m-sm-n3 {
    margin: -1.5rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1.5rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1.5rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1.5rem !important; }
  .m-sm-n4 {
    margin: -3rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -3rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -3rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -3rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -3rem !important; }
  .m-sm-n5 {
    margin: -4.5rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -4.5rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -4.5rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -4.5rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -4.5rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.375rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.375rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.375rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.375rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.375rem !important; }
  .m-md-2 {
    margin: 0.75rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.75rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.75rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.75rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.75rem !important; }
  .m-md-3 {
    margin: 1.5rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.5rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.5rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.5rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.5rem !important; }
  .m-md-4 {
    margin: 3rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 3rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 3rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 3rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 3rem !important; }
  .m-md-5 {
    margin: 4.5rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 4.5rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 4.5rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 4.5rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 4.5rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.375rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.375rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.375rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.375rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.375rem !important; }
  .p-md-2 {
    padding: 0.75rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.75rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.75rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.75rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.75rem !important; }
  .p-md-3 {
    padding: 1.5rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.5rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.5rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.5rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.5rem !important; }
  .p-md-4 {
    padding: 3rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 3rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 3rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 3rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 3rem !important; }
  .p-md-5 {
    padding: 4.5rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 4.5rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 4.5rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 4.5rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 4.5rem !important; }
  .m-md-n1 {
    margin: -0.375rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.375rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.375rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.375rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.375rem !important; }
  .m-md-n2 {
    margin: -0.75rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.75rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.75rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.75rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.75rem !important; }
  .m-md-n3 {
    margin: -1.5rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1.5rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1.5rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1.5rem !important; }
  .m-md-n4 {
    margin: -3rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -3rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -3rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -3rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -3rem !important; }
  .m-md-n5 {
    margin: -4.5rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -4.5rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -4.5rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -4.5rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -4.5rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.375rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.375rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.375rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.375rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.375rem !important; }
  .m-lg-2 {
    margin: 0.75rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.75rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.75rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.75rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.75rem !important; }
  .m-lg-3 {
    margin: 1.5rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.5rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.5rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.5rem !important; }
  .m-lg-4 {
    margin: 3rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 3rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 3rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 3rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 3rem !important; }
  .m-lg-5 {
    margin: 4.5rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 4.5rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 4.5rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 4.5rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 4.5rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.375rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.375rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.375rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.375rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.375rem !important; }
  .p-lg-2 {
    padding: 0.75rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.75rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.75rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.75rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.75rem !important; }
  .p-lg-3 {
    padding: 1.5rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.5rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.5rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.5rem !important; }
  .p-lg-4 {
    padding: 3rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 3rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 3rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 3rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 3rem !important; }
  .p-lg-5 {
    padding: 4.5rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 4.5rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 4.5rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 4.5rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 4.5rem !important; }
  .m-lg-n1 {
    margin: -0.375rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.375rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.375rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.375rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.375rem !important; }
  .m-lg-n2 {
    margin: -0.75rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.75rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.75rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.75rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.75rem !important; }
  .m-lg-n3 {
    margin: -1.5rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1.5rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1.5rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1.5rem !important; }
  .m-lg-n4 {
    margin: -3rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -3rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -3rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -3rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -3rem !important; }
  .m-lg-n5 {
    margin: -4.5rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -4.5rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -4.5rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -4.5rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -4.5rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.375rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.375rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.375rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.375rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.375rem !important; }
  .m-xl-2 {
    margin: 0.75rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.75rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.75rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.75rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.75rem !important; }
  .m-xl-3 {
    margin: 1.5rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.5rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.5rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.5rem !important; }
  .m-xl-4 {
    margin: 3rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 3rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 3rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 3rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 3rem !important; }
  .m-xl-5 {
    margin: 4.5rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 4.5rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 4.5rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 4.5rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 4.5rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.375rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.375rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.375rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.375rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.375rem !important; }
  .p-xl-2 {
    padding: 0.75rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.75rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.75rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.75rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.75rem !important; }
  .p-xl-3 {
    padding: 1.5rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.5rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.5rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.5rem !important; }
  .p-xl-4 {
    padding: 3rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 3rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 3rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 3rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 3rem !important; }
  .p-xl-5 {
    padding: 4.5rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 4.5rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 4.5rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 4.5rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 4.5rem !important; }
  .m-xl-n1 {
    margin: -0.375rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.375rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.375rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.375rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.375rem !important; }
  .m-xl-n2 {
    margin: -0.75rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.75rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.75rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.75rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.75rem !important; }
  .m-xl-n3 {
    margin: -1.5rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1.5rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1.5rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1.5rem !important; }
  .m-xl-n4 {
    margin: -3rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -3rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -3rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -3rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -3rem !important; }
  .m-xl-n5 {
    margin: -4.5rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -4.5rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -4.5rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -4.5rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -4.5rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #0255a3 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #012e57 !important; }

.text-secondary {
  color: #344786 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #1f2a4f !important; }

.text-success {
  color: #5a8a41 !important; }

a.text-success:hover, a.text-success:focus {
  color: #385629 !important; }

.text-info {
  color: #001325 !important; }

a.text-info:hover, a.text-info:focus {
  color: black !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #8a252a !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #4e1518 !important; }

.text-light, .footer {
  color: #f9fafb !important; }

a.text-light:hover, a.footer:hover, a.text-light:focus, a.footer:focus {
  color: #cfd4d9 !important; }

.text-dark {
  color: #3d464d !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1b1f22 !important; }

.text-body {
  color: #2b3136 !important; }

.text-muted {
  color: #687783 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn):not(.gform_button):not(.search-form__submit_desktop):not(.search-form__submit_mobile):not(.post-edit-link) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #82909c;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container, .entry-content h1,
  .entry-content h2,
  .entry-content h3,
  .entry-content h4,
  .entry-content h5,
  .entry-content h6,
  .entry-content p, .entry-content ul, .wp-block-image,
  .wp-block-quote,
  .wp-block-button.aligncenter,
  .wp-block-media-text {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table, .wp-block-table {
    border-collapse: collapse !important; }
    .table td, .wp-block-table td,
    .table th,
    .wp-block-table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #bbc2c9 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #bbc2c9; }
  .table .thead-dark th, .wp-block-table .thead-dark th {
    color: inherit;
    border-color: #bbc2c9; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.4rem; }

h1 {
  font-size: 1.875rem; }
  @media (min-width: 576px) {
    h1 {
      font-size: 2.125rem; } }
  @media (min-width: 768px) {
    h1 {
      font-size: 2.5rem; } }

h2 {
  font-size: 1.5rem; }
  @media (min-width: 576px) {
    h2 {
      font-size: 1.7rem; } }
  @media (min-width: 768px) {
    h2 {
      font-size: 2rem; } }

.entry-content ul {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0; }
  .entry-content ul li {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    display: block;
    margin-bottom: -1px;
    padding: 0.75rem 1.25rem;
    position: relative; }

.badge-primary a,
.bg-primary a {
  color: #bbc2c9;
  transition: color 300ms ease-in-out; }
  .badge-primary a:hover,
  .bg-primary a:hover {
    color: #fff; }

.card, .search-form__container {
  border-left: 0;
  border-radius: 0;
  border-right: 0; }
  @media (min-width: 576px) {
    .card, .search-form__container {
      border-left: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.4rem;
      border-right: 1px solid rgba(0, 0, 0, 0.125); } }

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important; }

.tease-text-alt {
  font-size: 0.875rem; }

.wp-block-table {
  font-size: 0.875rem; }

.navbar-brand {
  background-repeat: no-repeat;
  background-size: contain; }

.admin-bar .fixed-top {
  top: 2.875rem; }
  @media (min-width: 768px) {
    .admin-bar .fixed-top {
      top: 2rem; } }

@media screen and (max-width: 600px) {
  #wpadminbar {
    position: fixed; } }

.navbar-dark .navbar-nav .nav-link {
  color: #bbc2c9;
  transition: color 300ms ease-in-out; }
  .navbar-dark .navbar-nav .nav-link:hover {
    color: #fff; }

.header__desktop--top, .header__mobile--top {
  background-image: linear-gradient(106.7deg, #5D6BB5 0%, #0053A6 40%, #BD2632 100%);
  display: flex;
  justify-content: flex-start; }

.header__desktop--titles, .header__mobile--titles {
  align-self: stretch;
  background-color: #fff;
  flex: 1;
  max-width: 100%; }

.header__desktop {
  display: none; }
  @media (min-width: 992px) {
    .header__desktop {
      display: block; } }
  .header__desktop--titles .lead {
    display: none;
    font-size: 0.875rem;
    font-style: italic;
    max-width: 100%; }
    @media (min-width: 1200px) {
      .header__desktop--titles .lead {
        display: block; } }
  .header__desktop--titles .tld-title {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 0; }
  .header__desktop--top--triangle-left, .header__desktop--top--triangle-right {
    height: 180px;
    width: 75px;
    z-index: 1000; }
  .header__desktop--top--nav {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3.25rem 0 0; }
    .header__desktop--top--nav .dld-logo {
      margin-right: 2.25rem; }
    .header__desktop--top--nav nav {
      align-self: flex-end; }
      .header__desktop--top--nav nav .nav-item {
        background: rgba(0, 0, 0, 0.5);
        margin-right: 2px; }
        .header__desktop--top--nav nav .nav-item:last-child {
          margin-right: 0; }
        .header__desktop--top--nav nav .nav-item:first-child {
          margin-left: -75px;
          padding-left: 75px; }
      .header__desktop--top--nav nav .nav-link {
        color: #fff;
        font-weight: 700; }
    .header__desktop--top--nav .dropdown-menu .nav-item {
      background: inherit;
      margin-right: 0; }
      .header__desktop--top--nav .dropdown-menu .nav-item:last-child {
        margin-right: 0; }
      .header__desktop--top--nav .dropdown-menu .nav-item:first-child {
        margin-left: inherit;
        padding-left: inherit; }

.header__mobile {
  display: block; }
  @media (min-width: 992px) {
    .header__mobile {
      display: none; } }
  .header__mobile--actions {
    border-bottom: 0.0625rem solid #bbc2c9;
    display: flex; }
    .header__mobile--actions-toggle, .header__mobile--actions-search {
      color: #0255a3;
      font-size: 1rem;
      font-weight: 700; }
      .header__mobile--actions-toggle .fas, .header__mobile--actions-search .fas {
        color: #9fa9b2;
        margin-right: .5rem; }
    .header__mobile--actions-toggle {
      border-right: 0.0625rem solid #bbc2c9;
      flex: 1;
      text-align: center; }
    .header__mobile--actions-search {
      display: block;
      flex: 1;
      text-align: center; }
  .header__mobile--titles {
    padding: 18px 16px; }
  .header__mobile--top {
    border-bottom: 0.0625rem solid #bbc2c9; }
    .header__mobile--top--triangle-left, .header__mobile--top--triangle-right {
      height: 60px;
      width: 15px;
      z-index: 1000; }
    .header__mobile--top--right svg {
      height: 36px; }
  .header__mobile .navbar .dropdown-menu {
    border: 0; }

.jumbotron__cover {
  align-items: center;
  background-color: #000;
  background-position: 0 -20rem;
  background-size: cover;
  border-radius: 0;
  display: flex;
  justify-content: flex-end;
  min-height: 10rem;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .jumbotron__cover.has-parallax {
    background-attachment: fixed; }
  .jumbotron__cover.has-background-dim::before {
    background-color: inherit;
    bottom: 0;
    content: '';
    left: 0;
    opacity: .25;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1; }
  .jumbotron__cover--content {
    color: #fff;
    text-align: right;
    z-index: 1; }
    .jumbotron__cover--content .lead {
      font-size: 1rem;
      margin: 0; }

.footer a {
  color: #bbc2c9;
  transition: color 300ms ease-in-out; }
  .footer a:hover {
    color: #fff; }

.footer__copyright {
  font-size: 0.875rem; }

.form-label-heading, .search-form__topics h4, .search-form__types h4, .tld-search .searchandfilter .sf-field-category h4,
.tld-search .searchandfilter .sf-field-post_type h4,
.tld-search .searchandfilter .sf-field-tag h4 {
  background-color: #f4f5f6;
  border-bottom: 0.0625rem solid #bbc2c9;
  border-top: 0.0625rem solid #bbc2c9;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: .25rem;
  text-align: center;
  text-transform: uppercase; }

.search-form {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  padding: spacer 0; }
  @media (min-width: 576px) {
    .search-form {
      max-width: 60rem;
      padding: spacer; } }
  @media (min-width: 768px) {
    .search-form {
      padding: spacer 4.5rem; } }
  .search-form .fas {
    color: #17a2b8;
    font-size: 2rem; }
  .search-form__container {
    background-color: #fff;
    border: 0;
    overflow: hidden;
    padding: 0.75rem;
    text-align: center; }
    @media (min-width: 576px) {
      .search-form__container {
        border: 0.0625rem solid #0255a3;
        border-radius: 0.4rem; } }
    @media (min-width: 768px) {
      .search-form__container {
        padding: 0.75rem 1.5rem; } }
    .search-form__container h1,
    .search-form__container p {
      margin-bottom: 1.5rem; }
    .search-form__container h1 {
      font-size: 2rem; }
  .search-form__form {
    background-color: #fff; }
    .search-form__form label {
      width: 100%; }
  .search-form__icon {
    display: none; }
    @media (min-width: 576px) {
      .search-form__icon {
        display: block; } }
  .search-form__nav {
    display: flex;
    justify-content: space-between; }
    .search-form__nav .nav-item .accordion-dropdown-menu {
      list-style: none; }
      .search-form__nav .nav-item .accordion-dropdown-menu.collapsing, .search-form__nav .nav-item .accordion-dropdown-menu.show {
        display: flex; }
  .search-form__search, .tld-search__search-form {
    border: 0.0625rem solid #bbc2c9;
    border-radius: 0.4rem;
    flex: 1;
    padding: 0.75rem 0.75rem 0.75rem 0; }
    @media (min-width: 576px) {
      .search-form__search, .tld-search__search-form {
        padding: 0.75rem; } }
  .search-form__search-input, .tld-search__search-form .sf-input-text {
    border: 0;
    border-radius: .75rem;
    font-size: 1rem;
    height: calc(1.5em + 1rem + 2px);
    line-height: 1.5;
    padding: .5rem 1rem; }
    @media (min-width: 992px) {
      .search-form__search-input, .tld-search__search-form .sf-input-text {
        font-size: 1.25rem; } }
    .search-form__search-input:active, .tld-search__search-form .sf-input-text:active, .search-form__search-input:focus, .tld-search__search-form .sf-input-text:focus, .search-form__search-input:hover, .tld-search__search-form .sf-input-text:hover {
      border: 0;
      box-shadow: none;
      outline: none; }
  .search-form__submit_desktop {
    display: none; }
    @media (min-width: 576px) {
      .search-form__submit_desktop {
        display: block; } }
  .search-form__submit_mobile {
    display: block; }
    @media (min-width: 576px) {
      .search-form__submit_mobile {
        display: none; } }
    .search-form__submit_mobile .fas {
      color: #fff;
      font-size: 1.5rem; }

.tld-search__filters {
  border-bottom: 0.0625rem solid #0255a3; }
  @media (min-width: 768px) {
    .tld-search__filters {
      border-bottom: 0;
      border-right: 0.0625rem solid #bbc2c9; } }

.tld-search__overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  cursor: pointer;
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2; }

.tld-search__search-form {
  border: 0.5px solid #bbc2c9;
  margin: 0.75rem 0.375rem;
  padding: 0.75rem 0.375rem; }
  .tld-search__search-form-button {
    color: #344786;
    border-color: #344786;
    padding: 0.25rem 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background: 0;
    border: 0; }
    .tld-search__search-form-button:hover {
      color: #fff;
      background-color: #344786;
      border-color: #344786; }
    .tld-search__search-form-button:focus, .tld-search__search-form-button.focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 71, 134, 0.5); }
    .tld-search__search-form-button.disabled, .tld-search__search-form-button:disabled {
      color: #344786;
      background-color: transparent; }
    .tld-search__search-form-button:not(:disabled):not(.disabled):active, .tld-search__search-form-button:not(:disabled):not(.disabled).active,
    .show > .tld-search__search-form-button.dropdown-toggle {
      color: #fff;
      background-color: #344786;
      border-color: #344786; }
      .tld-search__search-form-button:not(:disabled):not(.disabled):active:focus, .tld-search__search-form-button:not(:disabled):not(.disabled).active:focus,
      .show > .tld-search__search-form-button.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(52, 71, 134, 0.5); }
    @media (prefers-reduced-motion: reduce) {
      .tld-search__search-form-button {
        transition: none; } }
  .tld-search__search-form .sf-input-text {
    font-size: 1rem; }
    @media (min-width: 992px) {
      .tld-search__search-form .sf-input-text {
        font-size: 1rem; } }

.tld-search .search-container {
  background-color: #f4f5f6;
  border: 0.15rem solid #bbc2c9;
  border-radius: 0.4rem;
  overflow: hidden; }
  .tld-search .search-container__input, .tld-search .searchandfilter {
    background-color: #fff; }

.tld-search .searchandfilter ul {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0; }
  .tld-search .searchandfilter ul li {
    background-color: transparent;
    border: 0;
    display: block;
    margin-bottom: -1px;
    padding: 0.75rem 1.25rem;
    position: relative; }

.tld-search .searchandfilter .sf-field-reset {
  margin-bottom: 0.75rem;
  padding: 1.5rem 0.75rem; }
  .tld-search .searchandfilter .sf-field-reset input {
    width: 100%; }

.tld-search .searchandfilter .sf-field-search label {
  width: 100%; }

.nav-search__form {
  align-items: stretch;
  display: flex;
  position: relative;
  width: 100%; }

.nav-search__input {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 0.875rem;
  border: 0;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.35);
  flex: 1 1 auto;
  line-height: 1.5;
  margin-bottom: 0;
  margin-right: 0;
  min-width: 18rem;
  padding: 0 0.5rem;
  position: relative;
  width: 1%; }

.nav-search__append {
  display: flex;
  margin-left: -0.5px; }
  .nav-search__append .btn, .nav-search__append .gform_button, .nav-search__append .search-form__submit_desktop, .nav-search__append .search-form__submit_mobile, .nav-search__append .tld-search .searchandfilter .sf-field-reset input, .tld-search .searchandfilter .sf-field-reset .nav-search__append input, .nav-search__append .post-edit-link, .nav-search__append .cat-links a, .cat-links .nav-search__append a, .nav-search__append .tags-links a, .tags-links .nav-search__append a {
    border-width: 0;
    padding: 0;
    position: relative;
    text-transform: uppercase;
    z-index: 2; }
    .nav-search__append .btn:focus, .nav-search__append .gform_button:focus, .nav-search__append .search-form__submit_desktop:focus, .nav-search__append .search-form__submit_mobile:focus, .nav-search__append .tld-search .searchandfilter .sf-field-reset input:focus, .tld-search .searchandfilter .sf-field-reset .nav-search__append input:focus, .nav-search__append .post-edit-link:focus, .nav-search__append .cat-links a:focus, .cat-links .nav-search__append a:focus, .nav-search__append .tags-links a:focus, .tags-links .nav-search__append a:focus {
      z-index: 3; }

.sidebar .widget .widget-title {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-align: center; }

.sidebar .widget ul {
  background: transparent;
  border: 0;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0; }
  .sidebar .widget ul li {
    background: transparent;
    background-color: transparent;
    border: 0;
    display: block;
    margin-bottom: -1px;
    padding: 0.75rem 1.25rem;
    position: relative; }

.topics-nav {
  background-color: #fff;
  border-top: #d7dcdf solid 0.0625rem;
  box-shadow: 0 12px 10px 0 rgba(0, 0, 0, 0.15);
  font-size: 0.875rem;
  justify-content: space-between;
  padding: .5rem 1.5rem; }
  @media (min-width: 992px) {
    .topics-nav {
      justify-content: flex-start; } }
  @media (min-width: 992px) {
    .topics-nav .nav.navbar-nav li:last-child, .topics-nav .navbar-nav.search-form__nav li:last-child {
      border-left: 0.0625rem solid #d7dcdf; } }
  .topics-nav .nav.navbar-nav .dropdown-menu, .topics-nav .navbar-nav.search-form__nav .dropdown-menu {
    border-radius: 0.125rem; }
    @media (max-width: 1199.98px) {
      .topics-nav .nav.navbar-nav .dropdown-menu, .topics-nav .navbar-nav.search-form__nav .dropdown-menu {
        border: 0;
        padding-left: 1.5rem; } }
    .topics-nav .nav.navbar-nav .dropdown-menu a, .topics-nav .navbar-nav.search-form__nav .dropdown-menu a {
      color: #0255a3;
      font-size: 0.875rem;
      font-weight: 700; }
    .topics-nav .nav.navbar-nav .dropdown-menu li:last-child, .topics-nav .navbar-nav.search-form__nav .dropdown-menu li:last-child {
      border-left: 0; }
  .topics-nav .nav-item {
    font-weight: 700; }
  .topics-nav .navbar-toggler-icon {
    color: #0255a3;
    padding: 0.375rem 0; }

.home-banner {
  background-image: url("/wp-content/themes/teaching-ld-wordpress/assets/images/pencils.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 20rem;
  padding: 5.5rem 1rem; }
  .home-banner__container {
    background-color: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 0.125rem;
    color: #0255a3;
    margin: 0 auto;
    max-width: 53.75rem;
    padding: 1.5rem 1.875rem;
    text-align: center; }
    .home-banner__container h1 {
      font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 2.25rem;
      margin: 0; }

.front-page__card {
  border: 0;
  border-radius: 0.125rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  overflow: hidden; }
  @media (min-width: 576px) {
    .front-page__card {
      min-width: 24rem; } }
  .front-page__card.card, .front-page__card.search-form__container {
    margin-bottom: 0.75rem; }
    @media (min-width: 576px) {
      .front-page__card.card, .front-page__card.search-form__container {
        margin-bottom: 1.5rem; } }
  .front-page__card .card-header {
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    border: 0;
    border-radius: 0.125rem 0.125rem 0 0;
    min-height: 20rem;
    padding: 0; }
    .front-page__card .card-header__no-bg {
      min-height: inherit; }
    .front-page__card .card-header__title {
      background-color: #0255a3;
      color: #fff;
      font-size: 1rem;
      padding: .75rem;
      text-transform: uppercase;
      width: fit-content; }
  .front-page__card .card-title {
    font-weight: 800; }
  .front-page__card .card-footer {
    background-color: #fff;
    border: 0; }
    .front-page__card .card-footer a {
      font-weight: 700; }
      .front-page__card .card-footer a span {
        color: #0F89FC; }

.front-page__image {
  height: 10rem;
  overflow: hidden; }

.front-page__subheading {
  font-size: 1.125rem;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  color: #687783;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 1.5rem;
  text-align: center; }
  @media (min-width: 576px) {
    .front-page__subheading {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .front-page__subheading {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .front-page__subheading {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .front-page__subheading {
      max-width: 1140px; } }

.front-page__topics {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1.5rem; }
  @media (min-width: 576px) {
    .front-page__topics {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .front-page__topics {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .front-page__topics {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .front-page__topics {
      max-width: 1140px; } }
  .front-page__topics-button {
    color: #0255a3;
    border-color: #0255a3;
    padding: 0.375rem 0.75rem;
    font-size: 1.5rem;
    line-height: 1.5;
    border-radius: 0.125rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 0.5px solid #0255a3;
    font-family: "Zilla Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    margin: 0.75rem; }
    .front-page__topics-button:hover {
      color: #fff;
      background-color: #0255a3;
      border-color: #0255a3; }
    .front-page__topics-button:focus, .front-page__topics-button.focus {
      box-shadow: 0 0 0 0.2rem rgba(2, 85, 163, 0.5); }
    .front-page__topics-button.disabled, .front-page__topics-button:disabled {
      color: #0255a3;
      background-color: transparent; }
    .front-page__topics-button:not(:disabled):not(.disabled):active, .front-page__topics-button:not(:disabled):not(.disabled).active,
    .show > .front-page__topics-button.dropdown-toggle {
      color: #fff;
      background-color: #0255a3;
      border-color: #0255a3; }
      .front-page__topics-button:not(:disabled):not(.disabled):active:focus, .front-page__topics-button:not(:disabled):not(.disabled).active:focus,
      .show > .front-page__topics-button.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(2, 85, 163, 0.5); }
    @media (max-width: 1200px) {
      .front-page__topics-button {
        font-size: calc(1.275rem + 0.3vw) ; } }
    @media (prefers-reduced-motion: reduce) {
      .front-page__topics-button {
        transition: none; } }

.tld-search .btn-lg, .tld-search .search-form__submit_desktop {
  border-radius: 0.125rem; }

.tld-search__filters-show {
  background-color: #d7dcdf;
  border-bottom: 0.0625rem solid #bbc2c9;
  border-top: 0.0625rem solid #bbc2c9;
  display: block;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: .25rem;
  padding: 0;
  text-align: center;
  text-transform: uppercase; }
  @media (min-width: 768px) {
    .tld-search__filters-show {
      display: none; } }
  .tld-search__filters-show button {
    color: black;
    font-weight: 700;
    height: 4rem;
    width: 100%; }

.tld-search__lead {
  font-size: 0.875rem;
  text-align: center; }

.tld-search__results {
  background-color: #f9fafb; }
  .tld-search__results .loader .fas {
    color: #82909c; }

.tld-search .searchandfilter {
  display: none; }
  @media (min-width: 768px) {
    .tld-search .searchandfilter {
      display: block; } }
