.front-page {
  &__card {
    border: 0;
    border-radius: $border-radius-sm;
    box-shadow: $card-shadow;
    overflow: hidden;

    @include media-breakpoint-up('sm') {
      min-width: 24rem;
    }

    &.card {
      @include media-breakpoint-up('sm') {
        margin-bottom: spacer(3);
      }

      margin-bottom: spacer(2);
    }

    .card-header {
      background-color: $white;
      background-repeat: no-repeat;
      background-size: cover;
      border: 0;
      border-radius: $border-radius-sm $border-radius-sm 0 0;
      min-height: 20rem;
      padding: 0;

      &__no-bg {
        min-height: inherit;
      }

      &__title {
        background-color: #0255a3;
        color: #fff;
        font-size: 1rem;
        padding: .75rem;
        text-transform: uppercase;
        width: fit-content;
      }
    }

    .card-title {
      font-weight: 800;
    }

    .card-footer {
      background-color: $white;
      border: 0;

      a {
        font-weight: $font-weight-bold;

        span {
          color: $blue-light;
        }
      }
    }
  }

  &__image {
    height: 10rem;
    overflow: hidden;
  }

  &__subheading {
    @include font-size(1.125rem);
    @include make-container;
    @include make-container-max-widths;

    color: $gray-600;
    font-family: $font-family-sans-serif;
    margin-bottom: spacer(3);
    text-align: center;
  }

  &__topics {
    @include make-container;
    @include make-container-max-widths();

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: spacer(3);

    &-button {
      @include button-outline-variant($primary);
      @include button-size($btn-padding-y, $btn-padding-x, $h4-font-size, $btn-line-height, $btn-border-radius);
      @include transition($btn-transition);

      border: ($btn-border-width / 2) solid $primary;
      font-family: $font-family-display;
      margin: spacer(2);
    }
  }
}
