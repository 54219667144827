.form-label-heading {
  @extend .mb-0;
  @extend .py-2;

  background-color: $gray-100;
  border-bottom: $border-search;
  border-top: $border-search;
  font-family: $font-family-sans-serif;
  font-size: $font-size-sm;
  font-weight: 400;
  letter-spacing: .25rem;
  text-align: center;
  text-transform: uppercase;
}

.search-form {
  @include make-container;
  @include media-breakpoint-up(sm) {
    max-width: 60rem;
    padding: spacer;
  }

  @include media-breakpoint-up(md) {
    padding: spacer spacer(5);
  }

  background-color: $white;
  margin-bottom: spacer(3);
  margin-top: spacer(3);
  padding: spacer 0;

  .fas {
    color: $cyan;
    font-size: $h2-font-size;
  }

  &__container {
    @extend .card;
    @include media-breakpoint-up(sm) {
      border: $border-form;
      border-radius: $border-radius;
    }

    @include media-breakpoint-up(md) {
      padding: spacer(2) spacer(3);
    }

    background-color: $white;
    border: 0;
    overflow: hidden;
    padding: spacer(2);
    text-align: center;

    h1,
    p {
      margin-bottom: spacer(3);
    }

    h1 {
      font-size: $h2-font-size;
    }
  }

  &__form {

    background-color: $white;

    label {
      @extend .mb-0;

      width: 100%;
    }
  }

  &__icon {
    @include media-breakpoint-up(sm) {
      display: block;
    }

    display: none;
  }

  &__nav {
    @extend .nav;
    display: flex;
    justify-content: space-between;

    .nav-item {
      .accordion-dropdown-menu {
        @extend .pl-0;

        list-style: none;

        &.collapsing,
        &.show {
          display: flex;
        }
      }
    }
  }

  &__search {
    @extend .row;
    @extend .no-gutters;
    @extend .align-items-center;

    @include media-breakpoint-up(sm) {
      padding: spacer(2);
    }

    border: $border-search;
    border-radius: $border-radius;
    flex: 1;
    padding: spacer(2) spacer(2) spacer(2) 0;
  }

  &__search-input {
    // sass-lint:disable-block no-important
    @extend .form-control;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-lg;
    }

    border: 0;
    border-radius: .75rem;
    font-size: $font-size-base;
    height: calc(1.5em + 1rem + 2px);
    line-height: 1.5;
    padding: .5rem 1rem;

    &:active,
    &:focus,
    &:hover {
      border: 0;
      box-shadow: none;
      outline: none;
    }
  }

  &__submit {
    &_desktop {
      @extend .btn;
      @extend .btn-lg;
      @extend .btn-primary;
      @include media-breakpoint-up(sm) {
        display: block;
      }

      display: none;
    }

    &_mobile {
      @extend .btn;
      @extend .btn-primary;
      @include media-breakpoint-up(sm) {
        display: none;
      }

      display: block;

      .fas {
        color: $white;
        font-size: $h4-font-size;
      }
    }
  }

  &__topics,
  &__types {
    h4 {
      @extend .form-label-heading;
    }
  }
}

.tld-search {
  &__filters {
    @extend .bg-white;
    @extend .col-12;
    @extend .col-md-4;
    @extend .col-lg-3;
    @extend .px-0;

    @include media-breakpoint-up(md) {
      border-bottom: 0;
      border-right: $border-search;
    }

    border-bottom: $border-form;
  }

  &__overlay {
    background-color: rgba(0,0,0,0.5);
    bottom: 0;
    cursor: pointer;
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  &__search-form {
    @extend .search-form__search;

    border: ($border-width / 2) solid $gray-300;
    margin: spacer(2) spacer(1);
    padding: spacer(2) spacer(1);

    &-button {
      @include button-outline-variant($secondary);
      @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-base, $btn-line-height-sm, $btn-border-radius-sm);
      @include transition($btn-transition);

      background: 0;
      border: 0;
    }

    .sf-input-text {
      @extend .search-form__search-input;

      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }

      font-size: $font-size-base;
    }
  }

  .search-container {
    background-color: $gray-100;
    border: .15rem solid $border-color;
    border-radius: $border-radius;
    overflow: hidden;

    &__input {
      background-color: $white;
    }
  }

  .searchandfilter {
    @extend .search-container__input;

    ul {
      @extend .px-0;
      @extend .py-2;

      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      padding-left: 0;

      li {
        @extend .py-1;

        background-color: transparent;
        border: 0;
        color: $list-group-color;
        display: block;
        margin-bottom: -$list-group-border-width;
        padding: $list-group-item-padding-y $list-group-item-padding-x;
        position: relative;
      }

      &.children {
        @extend .ml-0;
        @extend .py-0;
      }
    }

    .sf-field-reset {

      margin-bottom: spacer(2);
      padding: spacer(3) spacer(2);

      input {
        @extend .btn;
        @extend .btn-outline-danger;

        width: 100%;
      }
    }

    .sf-field-search {
      @extend .p-0;

      label {
        width: 100%;
      }
    }

    .sf-field-category,
    .sf-field-post_type,
    .sf-field-tag {
      @extend .p-0;
      @extend .mx-0;

      h4 {
        @extend .form-label-heading;
      }
    }
  }
}

.nav-search {
  &__form {
    align-items: stretch;
    display: flex;
    position: relative;
    width: 100%;
  }

  &__input {
    @include border-left-radius($btn-border-radius);
    @include border-right-radius(0);
    @include font-size($input-font-size-sm);

    border: 0;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.35);
    flex: 1 1 auto;
    line-height: $input-line-height-sm;
    margin-bottom: 0;
    margin-right: 0;
    min-width: 18rem;
    padding: 0 $input-padding-x-sm;
    position: relative;
    width: 1%;
  }

  &__append {

    display: flex;
    margin-left: -($border-width / 2);

    .btn {

      border-width: 0;
      padding: 0;
      position: relative;
      text-transform: uppercase;
      z-index: 2;

      &:focus {
        z-index: 3;
      }
    }
  }
}
