.jumbotron {

  &__cover {
    @extend .mb-5;

    align-items: center;
    background-color: $black;
    background-position: 0 -20rem;
    background-size: cover;
    border-radius: 0;
    display: flex;
    justify-content: flex-end;
    min-height: 10rem;
    overflow: hidden;
    position: relative;
    width: 100%;

    &.has-parallax {
      background-attachment: fixed;
    }

    &.has-background-dim {
      &::before {
        background-color: inherit;
        bottom: 0;
        content: '';
        left: 0;
        opacity: .25;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
      }
    }

    &--content {
      color: $white;
      text-align: right;
      z-index: 1;

      .lead {
        font-size: $font-size-base;
        margin: 0;
      }
    }
  }
}
