.footer {
  @extend .bg-secondary;
  @extend .text-light;

  a {
    color: $gray-300;
    transition: $link-transition;

    &:hover {
      color: $white;
    }
  }

  &__copyright {
    font-size: $font-size-sm;
  }
}
