.home-banner {
  background-image: url('/wp-content/themes/teaching-ld-wordpress/assets/images/pencils.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 20rem;
  padding: 5.5rem 1rem;

  &__container {
    background-color: $white;
    box-shadow: $card-shadow;
    border-radius: $border-radius-sm;
    color: $primary;
    margin: 0 auto;
    max-width: 53.75rem;
    padding: 1.5rem 1.875rem;
    text-align: center;

    h1 {
      font-family: $font-family-sans-serif;
      font-size: 1.5rem;
      font-weight: $font-weight-bold;
      line-height: 2.25rem;
      margin: 0;
    }
  }
}