.wp-block-image,
.wp-block-quote,
.wp-block-button.aligncenter,
.wp-block-media-text {
  @extend .container;
}

.wp-block-table {
  @extend .table;

  font-size: $font-size-sm;
}
