.topics-nav {
  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
  }

  background-color: $white;
  border-top: $gray-200 solid .0625rem;
  box-shadow: $nav-shadow;
  font-size: $font-size-sm;
  justify-content: space-between;
  padding: .5rem 1.5rem;


  .nav.navbar-nav {
    .nav-link {
      @extend .px-3;
    }

    li:last-child {
      @include media-breakpoint-up(lg) {
        border-left: .0625rem solid $gray-200;
      }
    }

    .dropdown-menu {
      @include media-breakpoint-down(lg) {
        border: 0;
        padding-left: spacer(3);
      }

      border-radius: $border-radius-sm;

      a {
        color: $primary;
        font-size: $font-size-sm;
        font-weight: $font-weight-bold;
      }

      li:last-child {
        border-left: 0;
      }
    }

  }

  .nav-item {
    font-weight: $font-weight-bold;
  }

  .navbar-toggler-icon {
    color: $primary;
    padding: ($spacer * .25) 0;
  }
}
