h1 {
  @include media-breakpoint-up(sm) {
    font-size: $h1-font-size * .85;
  }

  @include media-breakpoint-up(md) {
    font-size: $h1-font-size;
  }

  font-size: $h1-font-size * .75;
}

h2 {
  @include media-breakpoint-up(sm) {
    font-size: $h2-font-size * .85;
  }

  @include media-breakpoint-up(md) {
    font-size: $h2-font-size;
  }

  font-size: $h2-font-size * .75;
}

.entry-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    @extend .container;
  }

  ul {
    @extend .container;
    @extend .my-5;

    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-left: 0;

    li {
      background-color: $list-group-bg;
      border: $list-group-border-width solid $list-group-border-color;
      color: $list-group-color;
      display: block;
      margin-bottom: -$list-group-border-width;
      padding: $list-group-item-padding-y $list-group-item-padding-x;
      position: relative;

      ul {
        @extend .my-2;
      }
    }
  }
}

.badge-primary,
.bg-primary {
  a {
    color: $gray-300;
    transition: $link-transition;

    &:hover {
      color: $white;
    }
  }
}

.card {
  @include media-breakpoint-up(sm) {
    border-left: $card-border-width solid $card-border-color;
    border-radius: $border-radius;
    border-right: $card-border-width solid $card-border-color;
  }

  border-left: 0;
  border-radius: 0;
  border-right: 0;
}

.screen-reader-text {
  // sass-lint:disable-block no-important
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

.site-content {
  @extend .pt-4;
  @extend .mt-0;
}

.home {
  .site-content {
    @extend .pt-0;
  }
}

.tease-text-alt {
  font-size: $font-size-sm;
}
