.sidebar {
  .widget-area {
    @extend .bg-light;
    @extend .border;
    @extend .mb-3;
    @extend .pt-3;
    @extend .rounded;
  }

  .widget {
    @extend .mb-4;

    .widget-title {
      @extend .border-bottom;
      @extend .border-primary;
      @extend .h3;
      @extend .mb-3;
      @extend .mt-0;
      @extend .pb-3;

      font-family: $font-family-sans-serif;
      text-align: center;
    }

    ul {
      background: transparent;
      border: 0;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      padding-left: 0;

      li {
        background: transparent;
        background-color: transparent;
        border: 0;
        color: $list-group-color;
        display: block;
        margin-bottom: -$list-group-border-width;
        padding: $list-group-item-padding-y $list-group-item-padding-x;
        position: relative;

        a {
          @extend .d-block;
        }
      }
    }
  }
}
