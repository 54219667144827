.navbar-brand {
  // background-image: url('assets/images/JessHendricksLogo2019.svg?version=01272019');
  background-repeat: no-repeat;
  background-size: contain;
}

.admin-bar {
  .fixed-top {
    @include media-breakpoint-up(md) {
      top: 2rem;
    }

    top: 2.875rem;
  }
}

@media screen and (max-width: 600px) {
  // sass-lint:disable-block no-ids
  #wpadminbar {
    position: fixed;
  }
}

.navbar-dark {
  .navbar-nav {
    .nav-link {
      color: $gray-300;
      transition: $link-transition;

      &:hover {
        color: $white;
      }
    }
  }
}

.header {
  &__desktop--top,
  &__mobile--top {
    background-image: linear-gradient(106.7deg, #5D6BB5 0%, #0053A6 40%, #BD2632 100%);
    display: flex;
    justify-content: flex-start;
  }

  &__desktop--titles,
  &__mobile--titles {
    align-self: stretch;
    background-color: $white;
    flex: 1;
    max-width: 100%;
  }

  &__desktop {
    @include media-breakpoint-up(lg) {
      display: block;
    }

    display: none;


    &--titles {
      @extend .py-3;

      .lead {
        @extend .mb-0;
        @include media-breakpoint-up(xl) {
          display: block;
        }

        display: none;
        font-size: $font-size-sm;
        font-style: italic;
        max-width: 100%;

      }

      .tld-title {
        font-size: $h2-font-size;
        font-weight: $font-weight-normal;
        margin-bottom: 0;
      }
    }

    &--top {
      &--triangle-left,
      &--triangle-right {
        height: 180px;
        width: 75px;
        z-index: 1000;
      }

      &--nav {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 3.25rem 0 0;

        .dld-logo {
          margin-right: 2.25rem;
        }

        nav {
          align-self: flex-end;

          .nav-item {
            background: rgba($black, .5);
            margin-right: 2px;

            &:last-child {
              margin-right: 0;

              .nav-link {
                @extend .pr-3;
              }
            }

            &:first-child {
              margin-left: -75px;
              padding-left: 75px;
            }
          }

          .nav-link {
            @extend .px-2;

            color: $white;
            font-weight: $font-weight-bold;
          }
        }

        .dropdown-menu {
          .nav-item {
            background: inherit;
            margin-right: 0;

            &:last-child {
              margin-right: 0;

              .nav-link {
                @extend .pr-0;
              }
            }

            &:first-child {
              margin-left: inherit;
              padding-left: inherit;
            }
          }
        }
      }
    }
  }

  &__mobile {
    @include media-breakpoint-up(lg) {
      display: none;
    }

    display: block;

    &--actions {
      border-bottom: $border-search;
      display: flex;

      &-toggle,
      &-search {
        @extend .p-2;

        color: $primary;
        font-size: $font-size-base;
        font-weight: $font-weight-bold;

        .fas {
          color: $gray-400;
          margin-right: .5rem;
        }
      }

      &-toggle {
        border-right: $border-search;
        flex: 1;
        text-align: center;
      }

      &-search {
        display: block;
        flex: 1;
        text-align: center;
      }
    }

    &--titles {
      padding: 18px 16px;
    }

    &--top {
      border-bottom: $border-search;

      &--triangle-left,
      &--triangle-right {
        height: 60px;
        width: 15px;
        z-index: 1000;
      }

      &--right {
        @extend .py-2;
        @extend .pl-4;

        svg {
          height: 36px;
        }
      }
    }

    .navbar {
      .navbar-collapse {
        @extend .px-3;
        @extend .py-2;
      }

      .dropdown-menu {
        border: 0;
      }
    }
  }
}
